


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Consent } from '@/interface/api'
import sanitize from 'sanitize-html'

@Component({
  name: 'ConsentTemplate'
})
export default class ConsentTemplate extends Vue {
  @Prop({ default: () => [] }) private consents!: Consent[]
  @Prop({ default: false }) private loading!: boolean

  private sanitizedContents: { title: string; id: string; content: string }[] =
    []

  private mounted() {
    this.sanitizedContents = this.consents.map(consent => {
      return {
        title: consent.title,
        id: consent.id,
        content: sanitize(consent.content)
      }
    })
  }
}
