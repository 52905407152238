import request from '@/utils/request'
import { Consent, SearchParams } from '@/interface/api'

const prefix = '/api/v0/customer/clinics/consents'

export const retrieveTraetmentConsents = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  params: SearchParams
): Promise<Consent[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    method: 'get',
    params: params
  }).then(response => response.data.data.attributes)
