import request from '@/utils/request'
import { InflowSourcePostRequest } from '@/interface/api'

const prefix = 'api/v0/customer/medical-customers/inflow'

export const createInflowSource = (
  medicalBusinessId: string,
  inflowSource: InflowSourcePostRequest
): Promise<boolean> =>
  request({
    url: `${prefix}/${medicalBusinessId}/`,
    method: 'post',
    data: inflowSource
  })
    .then(() => true)
    .catch(() => false)
