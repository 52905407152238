import request from '@/utils/request'
import {
  MedicalTreatmentSpotCheck,
  MedicalTreatmentTimeframeSpotCheck
} from '@/interface/api'
import { AlertModule } from '@/store/modules/alert'

const prefix = '/api/v0/customer/clinics/treatment-spots/check/unauthorized'
const timeframeCheckPrefix =
  '/api/v0/customer/clinics/treatment-spots/timeframe-check/unauthorized'

export const checkMedicalTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  isFirstTreatment: number,
  treatmentCategoryName: string,
  targetDatetime: string,
  doctorId: string,
  treatmentCourseId: string,
  selectedSlot: number | undefined
): Promise<MedicalTreatmentSpotCheck> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    method: 'post',
    data: {
      target_datetime: new Date(targetDatetime),
      treatment_category_name: treatmentCategoryName,
      is_first_treatment: isFirstTreatment,
      slot_designation_number: selectedSlot,
      doctor_id: doctorId ? doctorId : undefined,
      treatment_course_id: treatmentCourseId ? treatmentCourseId : undefined,
    }
  })
    .then(response => {
      if (!response.data.data.attributes.result) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return response.data.data.attributes
    })
    .catch(e => {
      if (
        (e.data.data.attributes.message =
          'Cannot be reserved because it exceeds the latest reservation time')
      ) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '診察予約可能時間が過ぎましたので変更できません。'
        })
      } else {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return false
    })

export const checkMedicalTimeFrameTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  startsAt: string | Date,
  endsAt: string | Date,
  isFirstTreatment: number,
  treatmentCategoryName: string,
  doctorId: string | null,
  clinicalDepartmentId: string,
  treatmentCourseId: string | null,
  reservationType: number,
  medicalCustomerId: string | null
): Promise<MedicalTreatmentTimeframeSpotCheck> =>
  request({
    url: `${timeframeCheckPrefix}/${medicalBusinessId}/${clinicId}/`,
    method: 'post',
    data: {
      starts_at: startsAt,
      ends_at: endsAt,
      is_first_treatment: isFirstTreatment,
      treatment_category_name: treatmentCategoryName,
      doctor_id: doctorId,
      clinical_department_id: clinicalDepartmentId,
      treatment_course_id: treatmentCourseId,
      reservation_type: reservationType,
      medical_customer_id: medicalCustomerId
    }
  })
    .then(response => {
      if (!response.data.data.attributes.temporary_timeframe_id) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
        return response.data.data.attributes
      }
      return response.data.data.attributes
    })
    .catch(e => {
      if (
        (e.data.data.attributes.message =
          'Cannot be reserved because it exceeds the latest reservation time')
      ) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '診察予約可能時間が過ぎましたので変更できません。'
        })
      } else {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return false
    })
