











































































































































































































































































import { v4 as uuidv4 } from 'uuid'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SelectClinicTemplate from '@/views/treatment-reservation/templates/SelectClinicTemplate.vue'
import TreatmentDetailTemplate from '@/views/treatment-reservation/templates/TreatmentDetailTemplate'
import ReservationCautionTemplate from '@/views/treatment-reservation/templates/ReservationCautionTemplate.vue'
import ConsentTemplate from '@/views/treatment-reservation/templates/ConsentTemplate.vue'
import SelectDoctorTemplate from '@/views/treatment-reservation/templates/SelectDoctorTemplate.vue'
import SelectDateTimeTemplate from '@/views/treatment-reservation/templates/SelectDateTimeTemplate.vue'
import CustomerFreeDiscribeTemplate from '@/views/treatment-reservation/templates/CustomerFreeDiscribeTemplate.vue'
import PersonalInfomationTemplate from '@/views/treatment-reservation/templates/PersonalInfomationTemplate.vue'
import ConfirmTemplate from '@/views/treatment-reservation/templates/ConfirmTemplate.vue'
import {
  Clinic,
  ClinicResponse,
  ReservationCaution,
  Consent,
  Member,
  MedicalTreatmentSpot,
  CustomerFreeDescribe,
  PersonalInfomation,
  MedicalBusinessRegisterRequest,
  InflowSourcePostRequest,
  Spot,
  SpotRequest
} from '@/interface/api'
import { createInflowSource } from '@/api/inflow-source'
import { retrieveClinics } from '@/api/clinic'
import { retrieveTraetmentNotices } from '@/api/traetment-notice'
import { retrieveTraetmentConsents } from '@/api/traetment-consent'
import { retrieveMembers, retrieveTreatmentCourseMembers } from '@/api/member'
import {
  beforeCheckMedicalTreatmentSpot,
  beforeCheckMedicalTimeFrameTreatmentSpot
} from '@/api/treatment-before-check'
import { retrieveAllTreatmentSpots } from '@/api/treatment-spot'
import { retrieveCustomerDescription } from '@/api/traetment-customer-description'
import {
  checkMedicalTreatmentSpot,
  checkMedicalTimeFrameTreatmentSpot
} from '@/api/treatment-check'
import { createTreatment, createTimeframeTreatment } from '@/api/treatment'
import { getPublicHoliday } from '@/api/getPublicDay'
import { formatDate, formatUuid } from '@/utils/index'
import {
  DateFormat,
  NO_DESIRED_DOCTOR,
  TreatmentCategory
} from '@/utils/constants'
import { RouteName } from '@/router'
import { AlertModule } from '@/store/modules/alert'

@Component({
  name: 'TreatmentReservationPage',
  components: {
    SelectClinicTemplate,
    TreatmentDetailTemplate,
    ReservationCautionTemplate,
    ConsentTemplate,
    SelectDoctorTemplate,
    SelectDateTimeTemplate,
    CustomerFreeDiscribeTemplate,
    PersonalInfomationTemplate,
    ConfirmTemplate
  }
})
export default class TreatmentReservationPage extends Vue {
  @Prop({ default: '' }) private medicalBusinessId!: string
  @Prop({ default: false }) private canFirstReservationForEachDoctor!: boolean
  @Prop({ default: false })
  private canSecondLaterReservationForEachDoctor!: boolean

  @Prop({ default: false }) private canFaceToFace!: boolean
  @Prop({ default: false }) private canOnlineTreatment!: boolean
  @Prop({ default: 0 }) private webReservationSexChoices!: number
  @Prop({ default: 0 }) private requireKanjiName!: number
  @Prop({ required: true }) private targetClinicUpdate!: (
    clinic: Clinic
  ) => void

  private inflowSourceId = ''
  private clinics: ClinicResponse[] = []
  private selectedClinic: ClinicResponse | null = null
  private traetmentNotices: ReservationCaution[] = []
  private consents: Consent[] = []
  private customerFreeDescribe: CustomerFreeDescribe[] = []
  private publicHolidyList: string[] = []
  private perMinute = 10
  private selectedClinicalDepartmentId = ''
  private isFirstTreatment = 1
  private doctors: Member[] = []
  private selectedDoctorId = ''
  private selectedDoctorName = ''
  private selectedTreatmentCategoryName = ''
  private selectedClinicDeaprtmentTreatmentCourseId = ''
  private agreedConsentIds: string[] = []
  private startsAt = new Date()
  private endsAt = new Date()
  private temporaryReservationId = ''
  private customerDescription = ''
  private medicalTreatmentSpots: MedicalTreatmentSpot[] = []
  private medicalTreatmentSpotMap: Map<string, Spot[]> = new Map()
  private spotRequest: SpotRequest = {
    id: '',
    spot_datetime: '',
    select_slot_number: 0
  }

  private spotStartDatetime: Date | string = new Date()
  private spotEndDatetime: Date | string = new Date()
  private inputedPersonalInfo: PersonalInfomation = {
    phone_number: '',
    last_name: '',
    first_name: '',
    last_name_kana: '',
    first_name_kana: '',
    sex: 1,
    birthdate: ''
  }

  private request = {}
  private initLoading = false
  private loading = false
  private step = 0

  private get canFirstReservationForEachDoctorFlug() {
    if (!this.selectedClinic) return false
    const target = this.selectedClinic.clinical_departments.find(
      department => department.id === this.selectedClinicalDepartmentId
    )
    if (!target) return false
    return (
      this.isFirstTreatment === 1 &&
      this.canFirstReservationForEachDoctor &&
      target.selectable_doctor.is_first_treatment
    )
  }

  private get canSecondLaterReservationForEachDoctorFlug() {
    if (!this.selectedClinic) return false
    const target = this.selectedClinic.clinical_departments.find(
      department => department.id === this.selectedClinicalDepartmentId
    )
    if (!target) return false
    return (
      this.isFirstTreatment === 0 &&
      this.canSecondLaterReservationForEachDoctor &&
      target.selectable_doctor.is_repeated_treatment
    )
  }

  private get isSingleClinic() {
    return this.clinics.length === 1
  }

  private get reservationType() {
    if (!this.selectedClinic) return 0
    const target = this.selectedClinic.clinical_departments.find(
      department => department.id === this.selectedClinicalDepartmentId
    )
    if (this.selectedTreatmentCategoryName === TreatmentCategory.Video) {
      return target!.video_reservation_type
    } else {
      return target!.face_to_face_reservation_type
    }
  }

  @Watch('medicalBusinessId', { immediate: true })
  private async onMedicalBusinessIdChange() {
    if (!this.medicalBusinessId) return
    this.initLoading = true
    // 流入元
    const query = this.$route.query
    const keyValues: { key: string; value: string }[] = []
    for (const [key, value] of Object.entries(query)) {
      if (
        key !== 'from' &&
        key !== 'medicalBusinessId' &&
        key !== 'clinicId' &&
        key !== 'clinicalDepartmentId' &&
        key !== 'treatmentCorseId' &&
        key !== 'isFirstTreatment' &&
        key !== 'treatmentCategoryName' &&
        key !== 'spotId' &&
        key !== 'spotDateTime' &&
        key !== 'selectSlotNumber'
      ) {
        keyValues.push({ key, value: value! as string })
      }
    }
    this.inflowSourceId = uuidv4()
    const inflowRequest: InflowSourcePostRequest = {
      params: keyValues,
      inflow_id: this.inflowSourceId,
      line_id: ''
    }
    await createInflowSource(this.medicalBusinessId, inflowRequest)
    this.clinics = await retrieveClinics(this.medicalBusinessId)
    if (this.$route.query.from === 'web_form') {
      this.perMinute = 30
      const targetClinic = this.clinics.find(
        clinic => clinic.clinic.id === this.$route.query.clinicId
      )!
      this.selectedClinic = targetClinic
      this.selectedClinicalDepartmentId = String(
        this.$route.query.clinicalDepartmentId
      )
      this.isFirstTreatment = Number(this.$route.query.isFirstTreatment)
      this.selectedTreatmentCategoryName = String(
        this.$route.query.treatmentCategoryName
      )
      this.selectedClinicDeaprtmentTreatmentCourseId = String(
        this.$route.query.treatmentCorseId || ''
      )
      const spot: SpotRequest = {
        id: String(this.$route.query.spotId),
        spot_datetime: this.$route.query.spotDateTime as string,
        select_slot_number: 
          this.$route.query.selectSlotNumber !== undefined
            ? Number(this.$route.query.selectSlotNumber)
            : 0
      }
      this.addGtmTag(
        this.selectedClinic.clinic.gtm_tag_head,
        this.selectedClinic.clinic.gtm_tag_body
      )
      await this.setTreatmentSpot(new Date(), spot)
    } else if (this.$route.query.clinicId) {
      const target = this.clinics.find(
        clinic => clinic.clinic.id === this.$route.query.clinicId
      )!
      this.onClinicSelect(target)
    } else if (this.clinics.length === 1) {
      this.onClinicSelect(this.clinics[0])
    }
    this.initLoading = false
  }

  // クリニック選択
  private onClinicSelect(clinic: ClinicResponse) {
    this.selectedClinic = clinic
    this.selectedClinicalDepartmentId =
      this.selectedClinic.clinical_departments[0].id
    this.isFirstTreatment = 1
    if (this.canFaceToFace) {
      this.selectedTreatmentCategoryName = TreatmentCategory.FcaeToFace
    } else if (this.canOnlineTreatment) {
      this.selectedTreatmentCategoryName = TreatmentCategory.Video
    }
    this.addGtmTag(
      this.selectedClinic.clinic.gtm_tag_head,
      this.selectedClinic.clinic.gtm_tag_body
    )
    this.step = 1
  }

  // 診察詳細入力
  private onBackFromTreatmentDetail() {
    this.selectedClinicalDepartmentId = ''
    this.isFirstTreatment = 1
    this.selectedTreatmentCategoryName = ''
    this.step = 0
  }

  private async onTreatmentDetailInput(
    selectedClinicalDepartmentId: string,
    isFirstTreatment: number,
    selectedTreatmentCategoryName: string,
    selectedClinicDeaprtmentTreatmentCourseId: string
  ) {
    this.loading = true
    this.selectedClinicalDepartmentId = selectedClinicalDepartmentId
    this.isFirstTreatment = isFirstTreatment
    this.selectedTreatmentCategoryName = selectedTreatmentCategoryName
    this.selectedClinicDeaprtmentTreatmentCourseId =
      selectedClinicDeaprtmentTreatmentCourseId
    const department = this.selectedClinic!.clinical_departments.find(
      item => item.id === this.selectedClinicalDepartmentId
    )
    this.perMinute =
      this.selectedTreatmentCategoryName === TreatmentCategory.Video
        ? department!.video_reservation_interval
        : department!.face_to_face_reservation_interval
    const params = {
      treatment_category_name: this.selectedTreatmentCategoryName,
      is_first_treatment: this.isFirstTreatment,
      is_before_treatment: 1
    }
    this.traetmentNotices = await retrieveTraetmentNotices(
      this.medicalBusinessId,
      formatUuid(this.selectedClinic!.clinic.id),
      formatUuid(this.selectedClinicalDepartmentId),
      params
    )
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      this.loading = false
    } else {
      this.consents = await retrieveTraetmentConsents(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(this.selectedClinicalDepartmentId),
        params
      )
      if (this.consents.length !== 0) {
        this.step = 3
        this.loading = false
      } else {
        if (
          this.canFirstReservationForEachDoctorFlug ||
          this.canSecondLaterReservationForEachDoctorFlug
        ) {
          if (this.selectedClinicDeaprtmentTreatmentCourseId) {
            this.doctors = await retrieveTreatmentCourseMembers(
              this.medicalBusinessId,
              formatUuid(this.selectedClinic!.clinic.id),
              formatUuid(this.selectedClinicalDepartmentId),
              formatUuid(this.selectedClinicDeaprtmentTreatmentCourseId),
              this.isFirstTreatment,
              this.selectedTreatmentCategoryName
            )
          } else {
            this.doctors = await retrieveMembers(
              this.medicalBusinessId,
              formatUuid(this.selectedClinic!.clinic.id),
              formatUuid(this.selectedClinicalDepartmentId),
              this.isFirstTreatment,
              this.selectedTreatmentCategoryName
            )
          }
          if (this.doctors.length !== 0) {
            this.step = 4
            this.loading = false
            return
          }
        }
        await this.fetchTreatmentSpot(new Date())
        this.publicHolidyList = await getPublicHoliday()
        this.step = 5
        this.loading = false
      }
    }
  }

  // 診察注意
  private onBackFromTreatmentNotice() {
    this.traetmentNotices = []
    this.step = 1
  }

  private async onTreatmentNoticeCheck() {
    this.loading = true
    const params = {
      treatment_category_name: this.selectedTreatmentCategoryName,
      is_first_treatment: this.isFirstTreatment,
      is_before_treatment: 1
    }
    this.consents = await retrieveTraetmentConsents(
      this.medicalBusinessId,
      formatUuid(this.selectedClinic!.clinic.id),
      formatUuid(this.selectedClinicalDepartmentId),
      params
    )
    if (this.consents.length !== 0) {
      this.step = 3
      this.loading = false
    } else {
      if (
        this.canFirstReservationForEachDoctorFlug ||
        this.canSecondLaterReservationForEachDoctorFlug
      ) {
        if (this.selectedClinicDeaprtmentTreatmentCourseId) {
          this.doctors = await retrieveTreatmentCourseMembers(
            this.medicalBusinessId,
            formatUuid(this.selectedClinic!.clinic.id),
            formatUuid(this.selectedClinicalDepartmentId),
            formatUuid(this.selectedClinicDeaprtmentTreatmentCourseId),
            this.isFirstTreatment,
            this.selectedTreatmentCategoryName
          )
        } else {
          this.doctors = await retrieveMembers(
            this.medicalBusinessId,
            formatUuid(this.selectedClinic!.clinic.id),
            formatUuid(this.selectedClinicalDepartmentId),
            this.isFirstTreatment,
            this.selectedTreatmentCategoryName
          )
        }
        if (this.doctors.length !== 0) {
          this.step = 4
          this.loading = false
          return
        }
      }
      await this.fetchTreatmentSpot(new Date())
      this.publicHolidyList = await getPublicHoliday()
      this.step = 5
      this.loading = false
    }
  }

  // 同意書
  private onBackFromConsent() {
    this.consents = []
    this.agreedConsentIds = []
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      return
    }
    this.step = 1
  }

  private async onTreatmentConsentAgree() {
    this.loading = true
    this.agreedConsentIds = this.consents.map(consent => consent.id)
    if (
      this.canFirstReservationForEachDoctorFlug ||
      this.canSecondLaterReservationForEachDoctorFlug
    ) {
      if (this.selectedClinicDeaprtmentTreatmentCourseId) {
        this.doctors = await retrieveTreatmentCourseMembers(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          formatUuid(this.selectedClinicDeaprtmentTreatmentCourseId),
          this.isFirstTreatment,
          this.selectedTreatmentCategoryName
        )
      } else {
        this.doctors = await retrieveMembers(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          this.isFirstTreatment,
          this.selectedTreatmentCategoryName
        )
      }
      if (this.doctors.length !== 0) {
        this.step = 4
        this.loading = false
        return
      }
    }
    await this.fetchTreatmentSpot(new Date())
    this.publicHolidyList = await getPublicHoliday()
    this.step = 5
    this.loading = false
  }

  // 医師選択
  private async onBackFromSelectDoctor() {
    this.selectedDoctorId = ''
    this.selectedDoctorName = ''
    if (this.consents.length !== 0) {
      this.step = 3
      return
    }
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      return
    }
    this.step = 1
  }

  private async onSelectDoctor(doctorId: string, doctorName: string) {
    this.loading = true
    this.selectedDoctorId = doctorId
    this.selectedDoctorName = doctorName
    await this.fetchTreatmentSpot(new Date())
    this.publicHolidyList = await getPublicHoliday()
    this.step = 5
    this.loading = false
  }

  // 日時選択
  private onBackFromSelectDateTime() {
    this.medicalTreatmentSpots = []
    this.spotRequest = {
      id: '',
      spot_datetime: '',
      select_slot_number: 0
    }
    this.spotStartDatetime = new Date()
    this.spotEndDatetime = new Date()
    if (
      (this.canFirstReservationForEachDoctorFlug ||
        this.canFirstReservationForEachDoctorFlug) &&
      this.doctors.length !== 0
    ) {
      this.step = 4
      return
    }
    if (this.consents.length !== 0) {
      this.step = 3
      return
    }
    if (this.traetmentNotices.length !== 0) {
      this.step = 2
      return
    }
    this.step = 1
  }

  private async setTreatmentSpot(date: Date, spot: SpotRequest) {
    this.loading = true
    if (this.reservationType) {
      const checkresult = await this.timeframeSpotBeforeCheck(
        date,
        spot as SpotRequest
      )
      if (checkresult) {
        const params = {
          treatment_category_name: this.selectedTreatmentCategoryName,
          is_first_treatment: this.isFirstTreatment,
          is_before_treatment: 1
        }
        this.customerFreeDescribe = await retrieveCustomerDescription(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          params
        )
        this.step = 7
        this.loading = false
      } else {
        await this.fetchTreatmentSpot(new Date())
        this.publicHolidyList = await getPublicHoliday()
        this.step = 5
        this.loading = false
      }
    } else {
      const checkresult = await this.beforeCheck(date, spot as SpotRequest)
      if (checkresult) {
        const params = {
          treatment_category_name: this.selectedTreatmentCategoryName,
          is_first_treatment: this.isFirstTreatment,
          is_before_treatment: 1
        }
        this.customerFreeDescribe = await retrieveCustomerDescription(
          this.medicalBusinessId,
          formatUuid(this.selectedClinic!.clinic.id),
          formatUuid(this.selectedClinicalDepartmentId),
          params
        )
        this.step = 7
        this.loading = false
      } else {
        await this.fetchTreatmentSpot(new Date())
        this.publicHolidyList = await getPublicHoliday()
        this.step = 5
        this.loading = false
      }
    }
  }

  // 個人情報入力
  private async onBackFromPersonalInfomation() {
    this.step = 5
    await this.fetchTreatmentSpot(new Date())
    this.publicHolidyList = await getPublicHoliday()
  }

  private onInputPersonalInfo(
    personalInfomation: PersonalInfomation,
    customerDescription: string
  ) {
    this.loading = true
    this.inputedPersonalInfo = personalInfomation
    this.customerDescription = customerDescription
    this.step = 8
    this.loading = false
  }

  private async editInfo(step: number) {
    if (step === 0) {
      this.selectedClinicalDepartmentId = ''
      this.isFirstTreatment = 1
      this.selectedTreatmentCategoryName = ''
      this.selectedClinicDeaprtmentTreatmentCourseId = ''
      this.traetmentNotices = []
      this.consents = []
      this.agreedConsentIds = []
      this.medicalTreatmentSpots = []
      this.spotRequest = {
        id: '',
        spot_datetime: '',
        select_slot_number: 0
      }
      this.spotStartDatetime = new Date()
      this.spotEndDatetime = new Date()
      this.customerFreeDescribe = []
      this.customerDescription = ''
    }
    if (step === 1) {
      this.consents = []
      this.agreedConsentIds = []
      this.medicalTreatmentSpots = []
      this.spotRequest = {
        id: '',
        spot_datetime: '',
        select_slot_number: 0
      }
      this.spotStartDatetime = new Date()
      this.spotEndDatetime = new Date()
      this.customerFreeDescribe = []
      this.customerDescription = ''
    }
    if (step === 4) {
      this.selectedDoctorId = ''
      this.selectedDoctorName = ''
    }
    if (step === 5) {
      this.spotRequest = {
        id: '',
        spot_datetime: '',
        select_slot_number: 0
      }
      this.spotStartDatetime = new Date()
      this.spotEndDatetime = new Date()
      this.customerFreeDescribe = []
      this.customerDescription = ''
      await this.fetchTreatmentSpot(new Date())
      this.publicHolidyList = await getPublicHoliday()
    }
    this.step = step
  }

  private async send() {
    this.loading = true
    if (this.reservationType) {
      const request: MedicalBusinessRegisterRequest = {
        customer: {
          phone_number: this.inputedPersonalInfo.phone_number,
          last_name: this.inputedPersonalInfo.last_name,
          first_name: this.inputedPersonalInfo.first_name,
          last_name_kana: this.inputedPersonalInfo.last_name_kana,
          first_name_kana: this.inputedPersonalInfo.first_name_kana,
          birthday: this.inputedPersonalInfo.birthdate,
          sex: this.inputedPersonalInfo.sex
        },
        treatment: {
          clinic_id: this.selectedClinic!.clinic.id,
          clinical_department_id: this.selectedClinicalDepartmentId,
          is_first_treatment: this.isFirstTreatment,
          treatment_category_name: this.selectedTreatmentCategoryName,
          prior_consents: this.agreedConsentIds,
          customer_description: this.customerDescription,
          customer_describe_id:
            this.customerFreeDescribe.length !== 0
              ? this.customerFreeDescribe[0].id
              : '',
          starts_at: this.spotStartDatetime,
          ends_at: this.spotEndDatetime,
          temporary_reservation_id: this.temporaryReservationId,
          treatment_course_id: this.selectedClinicDeaprtmentTreatmentCourseId,
          reservation_type: 1
        },
        inflow_id: this.inflowSourceId
      }
      const checkResult = await checkMedicalTimeFrameTreatmentSpot(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        this.spotStartDatetime,
        this.spotEndDatetime,
        this.isFirstTreatment,
        this.selectedTreatmentCategoryName,
        this.selectedDoctorId !== NO_DESIRED_DOCTOR.value
          ? this.selectedDoctorId
          : null,
        this.selectedClinicalDepartmentId,
        this.selectedClinicDeaprtmentTreatmentCourseId
          ? this.selectedClinicDeaprtmentTreatmentCourseId
          : null,
        1,
        null
      )
      if (checkResult && checkResult.flag) {
        request.treatment.starts_at = checkResult.starts_at
        request.treatment.ends_at = checkResult.ends_at
        request.treatment.temporary_timeframe_id =
          checkResult.temporary_timeframe_id
        const res = await createTimeframeTreatment(
          this.medicalBusinessId,
          request
        )
        if (!res.result) {
          // 診察登録失敗の場合はエラー画面に遷移
          this.loading = false
          if (res.message === 'already_same_phone_number_is_registered') return
          this.$router.push({ name: RouteName.ERROR })
          return
        }
        this.targetClinicUpdate(this.selectedClinic!.clinic)
        this.loading = false
        this.$router.push({
          name: RouteName.TREATMENT_RESERVATION_COMPLETE,
          params: { medicalBusinessId: this.medicalBusinessId },
          query: this.$route.query
        })
      } else {
        // 枠が取れなかった場合は枠選択に遷移
        this.loading = false
        await this.editInfo(5)
      }
    } else {
      let checkResult = await checkMedicalTreatmentSpot(
        this.medicalBusinessId,
        formatUuid(this.selectedClinic!.clinic.id),
        formatUuid(this.selectedClinicalDepartmentId),
        this.isFirstTreatment,
        this.selectedTreatmentCategoryName,
        this.spotRequest.spot_datetime,
        this.selectedDoctorId &&
        this.selectedDoctorId !== NO_DESIRED_DOCTOR.value ? this.selectedDoctorId : '',
        this.selectedClinicDeaprtmentTreatmentCourseId,
        this.spotRequest.select_slot_number
      )
      if (checkResult.result) {
        this.temporaryReservationId = checkResult.temporary_reservation_id
        this.startsAt = checkResult.treatment.starts_at
        this.endsAt = checkResult.treatment.ends_at
        const request: MedicalBusinessRegisterRequest = {
          customer: {
            phone_number: this.inputedPersonalInfo.phone_number,
            last_name: this.inputedPersonalInfo.last_name,
            first_name: this.inputedPersonalInfo.first_name,
            last_name_kana: this.inputedPersonalInfo.last_name_kana,
            first_name_kana: this.inputedPersonalInfo.first_name_kana,
            birthday: this.inputedPersonalInfo.birthdate,
            sex: this.inputedPersonalInfo.sex
          },
          treatment: {
            clinic_id: this.selectedClinic!.clinic.id,
            clinical_department_id: this.selectedClinicalDepartmentId,
            is_first_treatment: this.isFirstTreatment,
            treatment_category_name: this.selectedTreatmentCategoryName,
            prior_consents: this.agreedConsentIds,
            customer_description: this.customerDescription,
            customer_describe_id:
              this.customerFreeDescribe.length !== 0
                ? this.customerFreeDescribe[0].id
                : '',
            starts_at: new Date(this.startsAt),
            ends_at: new Date(this.endsAt),
            temporary_reservation_id: this.temporaryReservationId,
            treatment_course_id: this.selectedClinicDeaprtmentTreatmentCourseId,
            reservation_type: 0
          },
          inflow_id: this.inflowSourceId
        }
        const res = await createTreatment(
          this.medicalBusinessId,
          formatUuid(this.temporaryReservationId),
          request
        )
        if (!res.result) {
          // 診察登録失敗の場合はエラー画面に遷移
          this.loading = false
          if (res.message === 'already_same_phone_number_is_registered') return
          this.$router.push({ name: RouteName.ERROR })
          return
        }
        // 診察登録成功の場合は完了画面に遷移
        if (
          this.startsAt !== this.spotStartDatetime ||
          this.endsAt !== this.spotEndDatetime
        ) {
          AlertModule.SetWarningAlert({
            warningTime: new Date(),
            message: `指定した診察枠が確保できなかったため、\n${
              formatDate(this.startsAt, DateFormat.YYYY_MM_DD_HH_mm) +
              ' 〜 ' +
              formatDate(this.endsAt, DateFormat.YYYY_MM_DD_HH_mm)
            }で診察予約をお取りしています。`
          })
        }
        this.targetClinicUpdate(this.selectedClinic!.clinic)
        this.loading = false
        console.log(res)
        this.addAffiliagteTag(this.selectedClinic!.clinic.affiliate_tag_head, this.selectedClinic!.clinic.affiliate_tag_body, res.res?.medical_customer_id, res.res?.medical_record_code)
        this.$router.push({
          name: RouteName.TREATMENT_RESERVATION_COMPLETE,
          params: { medicalBusinessId: this.medicalBusinessId },
          query: this.$route.query
        })
        return
      }
      // 枠が取れなかった場合は枠選択に遷移
      this.loading = false
      await this.editInfo(5)
    }
  }

  private async beforeCheck(date: Date, spot: SpotRequest): Promise<boolean> {
    let res = await beforeCheckMedicalTreatmentSpot(
      this.medicalBusinessId,
      formatUuid(this.selectedClinic!.clinic.id),
      formatUuid(this.selectedClinicalDepartmentId),
      this.isFirstTreatment,
      this.selectedTreatmentCategoryName,
      spot.spot_datetime,
      this.selectedDoctorId &&
      this.selectedDoctorId !== NO_DESIRED_DOCTOR.value ? this.selectedDoctorId : '',
      this.selectedClinicDeaprtmentTreatmentCourseId,
      spot.select_slot_number
    )
    if (res.result) {
      this.spotRequest = spot
      this.spotStartDatetime = new Date(res.treatment.starts_at)
      this.spotEndDatetime = new Date(res.treatment.ends_at)
      return true
    }
    return false
  }

  private async timeframeSpotBeforeCheck(
    date: Date,
    spot: SpotRequest
  ): Promise<boolean> {
    let selectSlot = 0
    if (spot.select_slot_number === undefined) {
      if (spot.first_unit_available! > 0) {
        selectSlot = 0
      } else if (spot.second_unit_available! > 0) {
        selectSlot = 1
      } else if (spot.third_unit_available! > 0) {
        selectSlot = 2
      }
    } else {
      selectSlot = spot.select_slot_number
    }
    const slotMinute = selectSlot * 10
    const startDate = new Date(spot.spot_datetime)
    startDate.setMinutes(startDate.getMinutes() + slotMinute)
    const checkResult = await beforeCheckMedicalTimeFrameTreatmentSpot(
      this.medicalBusinessId,
      formatUuid(this.selectedClinic!.clinic.id),
      startDate,
      this.isFirstTreatment,
      this.selectedTreatmentCategoryName,
      this.selectedDoctorId !== NO_DESIRED_DOCTOR.value
        ? this.selectedDoctorId
        : null,
      this.selectedClinicalDepartmentId,
      this.selectedClinicDeaprtmentTreatmentCourseId
        ? this.selectedClinicDeaprtmentTreatmentCourseId
        : null,
      1,
      null
    )
    if (checkResult.flag) {
      this.spotRequest = spot
      this.spotStartDatetime = checkResult.starts_at
      this.spotEndDatetime = checkResult.ends_at
      return true
    }
    return false
  }

  private async fetchTreatmentSpot(startDate: Date) {
    const params = {
      reservation_spot_date: formatDate(
        startDate,
        DateFormat.YYYY_MM_DD_HYPHEN
      ),
      treatment_category_name: this.selectedTreatmentCategoryName,
      is_first_treatment: this.isFirstTreatment,
      treatment_course_id: this.selectedClinicDeaprtmentTreatmentCourseId || undefined,
      doctor_id: this.selectedDoctorId && this.selectedDoctorId !== NO_DESIRED_DOCTOR.value ? this.selectedDoctorId : undefined,
    }
    this.medicalTreatmentSpots = await retrieveAllTreatmentSpots(
      this.medicalBusinessId,
      formatUuid(this.selectedClinic!.clinic.id),
      formatUuid(this.selectedClinicalDepartmentId),
      params
    )
    this.medicalTreatmentSpotMap = new Map()
    this.medicalTreatmentSpots.forEach(spots => {
      this.medicalTreatmentSpotMap.set(spots.day, spots.spots)
    })
  }

  private addGtmTag(head: string, body: string) {
    if (!head || !body) return
    const script = document.createElement('script')
    const replaceHead1 = head.replace(/<script>/, '')
    const replaceHead2 = replaceHead1.replace(/<\/script>/, '')
    const replaceHead3 = replaceHead2.replace(/<!-- Google Tag Manager -->/, '')
    const replaceHead4 = replaceHead3.replace(
      /<!-- End Google Tag Manager -->/,
      ''
    )
    script.innerHTML = replaceHead4

    const noscript = document.createElement('noscript')
    const replaceBody1 = body.replace(/<noscript>/, '')
    const replaceBody2 = replaceBody1.replace(/<\/noscript>/, '')
    const replaceBody3 = replaceBody2.replace(
      /<!-- Google Tag Manager \(noscript\) -->/,
      ''
    )
    const replaceBody4 = replaceBody3.replace(
      /<!-- End Google Tag Manager \(noscript\) -->/,
      ''
    )
    noscript.innerHTML = replaceBody4
    document.head.appendChild(script)
    document.body.appendChild(noscript)
  }

  private addAffiliagteTag(head: string, body: string, customerId?: string, karteNo?: string) {
    if (head) {
      let replaceHead1 = head
      if (customerId) {
        replaceHead1 = head.replaceAll('${患者ID}', customerId)
      }
      let replaceHead2 = replaceHead1
      if (karteNo) {
        replaceHead2 = replaceHead1.replaceAll('${診察券番号}', karteNo)
      }
      console.log(replaceHead2)
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = replaceHead2;
       // scriptタグを抽出して実行
      const scriptElements = tempDiv.querySelectorAll('script');
      scriptElements.forEach(scriptElement => {
        const newScript = document.createElement('script');
        if (scriptElement.src) {
          newScript.src = scriptElement.src;
        } else {
          newScript.innerHTML = scriptElement.innerHTML;
        }
        document.head.appendChild(newScript); // 動的にscriptタグをbodyに追加して実行
      });

      // noscriptタグの内容を処理
      const noScriptElements = tempDiv.querySelectorAll('noscript');
      noScriptElements.forEach(noScriptElement => {
        // noscriptの内容をそのままdivに追加
        document.head.appendChild(noScriptElement)
      });
    }

    if (body) {
      let replaceBody1 = body
      if (customerId) {
        replaceBody1 = body.replaceAll('${患者ID}', customerId)
      }
      let replaceBody2 = replaceBody1
      if (karteNo) {
        replaceBody2 = replaceBody1.replaceAll('${診察券番号}', karteNo)
      }
      console.log(replaceBody2)
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = replaceBody2;
       // scriptタグを抽出して実行
      const scriptElements = tempDiv.querySelectorAll('script');
      scriptElements.forEach(scriptElement => {
        const newScript = document.createElement('script');
        if (scriptElement.src) {
          newScript.src = scriptElement.src;
        } else {
          newScript.innerHTML = scriptElement.innerHTML;
        }
        document.body.appendChild(newScript); // 動的にscriptタグをbodyに追加して実行
      });

      // noscriptタグの内容を処理
      const noScriptElements = tempDiv.querySelectorAll('noscript');
      noScriptElements.forEach(noScriptElement => {
        // noscriptの内容をそのままdivに追加
        document.body.appendChild(noScriptElement)
      });
    }
  }
}
