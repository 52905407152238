import request from '@/utils/request'
import { ReservationCaution, SearchParams } from '@/interface/api'

const prefix = '/api/v0/customer/clinics/traetment-notices'

export const retrieveTraetmentNotices = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  params: SearchParams
): Promise<ReservationCaution[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    method: 'get',
    params: params
  }).then(response => response.data.data.attributes)
