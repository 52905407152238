import request from '@/utils/request'
import { MedicalTreatmentSpot, SearchParams } from '@/interface/api'

const prefix = '/api/v0/customer/clinics/treatment-spots/unauthorized'

export const retrieveAllTreatmentSpots = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  params: SearchParams
): Promise<MedicalTreatmentSpot[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    params: params,
    method: 'get'
  }).then(response => response.data.data.attributes.schedules)
