import request from '@/utils/request'
import { MedicalTreatmentSpotBeforeCheckResponse, MedicalTreatmentTimeframeSpotCheck } from '@/interface/api'
import { AlertModule } from '@/store/modules/alert'

const prefix =
  '/api/v0/customer/clinics/treatment-spots/before-check/unauthorized'
const timeframeCheckPrefix =
  '/api/v0/customer/clinics/treatment-spots/timeframe-before-check/unauthorized'

export const beforeCheckMedicalTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  isFirstTreatment: number,
  treatmentCategoryName: string,
  targetDatetime: string,
  doctorId: string,
  treatmentCourseId: string,
  selectedSlot: number | undefined
): Promise<MedicalTreatmentSpotBeforeCheckResponse> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    method: 'get',
    params: {
      is_first_treatment: isFirstTreatment,
      treatment_category_name: treatmentCategoryName,
      target_datetime: new Date(targetDatetime),
      doctor_id: doctorId ? doctorId : undefined,
      slot_designation_number: selectedSlot,
      treatment_course_id: treatmentCourseId ? treatmentCourseId : undefined,
    }
  })
    .then(response => {
      if (!response.data.data.attributes.result) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠が確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return response.data.data.attributes
    })
    .catch(() => {
      AlertModule.SetErrorAlert({
        errorTime: new Date(),
        message: 'エラーが発生しました'
      })
      return false
    })

export const beforeCheckMedicalTimeFrameTreatmentSpot = (
  medicalBusinessId: string,
  clinicId: string,
  startsAt: string | Date,
  isFirstTreatment: number,
  treatmentCategoryName: string,
  doctorId: string | null,
  clinicalDepartmentId: string,
  treatmentCourseId: string | null,
  reservationType: number,
  medicalCustomerId: string | null
): Promise<MedicalTreatmentTimeframeSpotCheck> =>
  request({
    url: `${timeframeCheckPrefix}/${medicalBusinessId}/${clinicId}/`,
    method: 'post',
    data: {
      starts_at: startsAt,
      is_first_treatment: isFirstTreatment,
      treatment_category_name: treatmentCategoryName,
      doctor_id: doctorId,
      clinical_department_id: clinicalDepartmentId,
      treatment_course_id: treatmentCourseId,
      reservation_type: reservationType,
      medical_customer_id: medicalCustomerId
    }
  })
    .then(response => {
      if (!response.data.data.attributes.flag) {
        AlertModule.SetErrorAlert({
          errorTime: new Date(),
          message:
            '指定した診察枠を確保できませんでした。\n診察枠を選択し直してください。'
        })
      }
      return response.data.data.attributes
    })
    .catch(() => {
      AlertModule.SetErrorAlert({
        errorTime: new Date(),
        message: 'エラーが発生しました'
      })
      return false
    })
