



























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Address, ClinicResponse, ClinicDepartment } from '@/interface/api'
import { formatDate } from '@/utils/index'
import { GOOGLE_MAP_LINK_BASE_URL, DateFormat } from '@/utils/constants'

@Component({
  name: 'SelectClinicTemplate'
})
export default class SelectClinicTemplate extends Vue {
  @Prop({ default: () => [] }) private clinics!: ClinicResponse[]
  @Prop({ default: () => [] }) private clinicalDepartments!: ClinicDepartment[]

  private displayBusinessHours(
    clinic: ClinicResponse
  ): { time: number; hour: string; days: number[] }[] {
    const businessHours: { time: number; hour: string; days: number[] }[] = []
    clinic.business_hours.forEach(hour => {
      const open = hour.open_time.split(':')
      const close = hour.close_time.split(':')
      const openDate = new Date(1970, 1, 1, Number(open[0]), Number(open[1]))
      const endDate = new Date(1970, 1, 1, Number(close[0]), Number(close[1]))
      const hourStr =
        formatDate(openDate, DateFormat.HH_mm) +
        '-' +
        formatDate(endDate, DateFormat.HH_mm)
      let targetIndex = businessHours.findIndex(
        businessHour => businessHour.hour === hourStr
      )
      if (targetIndex < 0) {
        businessHours.push({
          time: openDate.getTime(),
          hour: hourStr,
          days: []
        })
        targetIndex = businessHours.length - 1
      }
      businessHours[targetIndex].days.push(hour.day)
    })
    return businessHours.sort((a, b) => a.time - b.time)
  }

  private jointAddress(address: Address): string {
    return (
      address.prefecture +
      address.city +
      address.other1 +
      (address.other2 ? ' ' + address.other2 : '') +
      (address.other3 ? ' ' + address.other3 : '') +
      (address.other4 ? ' ' + address.other4 : '') +
      (address.other5 ? ' ' + address.other5 : '') +
      (address.other6 ? ' ' + address.other6 : '')
    )
  }

  private googleMapUrl(url: string) {
    window.open(url, '_blank')
  }

  private searchGoogleMap(address: Address) {
    const params = this.jointAddress(address)
    window.open(GOOGLE_MAP_LINK_BASE_URL + encodeURI(params), '_blank')
  }
}
