




































import { Component, Prop, Vue } from 'vue-property-decorator'
import dayjs from '@/plugins/dayjs'
import {
  ClinicResponse,
  MedicalTreatmentSpot,
  Spot,
  SpotRequest
} from '@/interface/api'
import { formatDate } from '@/utils/index'
import { DateFormat } from '@/utils/constants'
@Component({})
export default class TreatmentSpotSelectTemplate extends Vue {
  @Prop({ default: () => null }) private clinic!: ClinicResponse
  @Prop({ default: () => [] }) private publicHolidyList!: string[]
  @Prop({ default: 0 }) private reservationType!: number
  @Prop({ default: () => null }) private medicalTreatmentSpotMap!: Map<
    string,
    Spot[]
  >

  @Prop({ default: () => [] })
  private medicalTreatmentSpots!: MedicalTreatmentSpot[]

  private get targetDates(): { label: string, date: string }[] {
    const dates = this.medicalTreatmentSpots.map(treatmentSpot => {
      return {
        label:
          formatDate(new Date(treatmentSpot.day), DateFormat.D) +
          '\n' +
          formatDate(new Date(treatmentSpot.day), DateFormat.dd),
        date: treatmentSpot.day
      }
    })
    return dates
  }

  private get treatmentSpotTimes(): string[] {
    const times: string[] = []
    const startTimes = this.clinic.business_hours.map(businessHours => {
      const time = businessHours.open_time.split(':')
      const date = new Date(1970, 0, 1, Number(time[0]), Number(time[1]))
      return dayjs(date)
    })
    const minStartTime = dayjs.min(startTimes)
    const endTimes = this.clinic.business_hours.map(businessHours => {
      const time = businessHours.close_time.split(':')
      const date = new Date(1970, 0, 1, Number(time[0]), Number(time[1]))
      return dayjs(date)
    })
    const maxEndTime = dayjs.max(endTimes)
    const intervalCount = maxEndTime.diff(minStartTime, 'minute') / 10
    times.push(minStartTime.format(DateFormat.HH_mm))
    for (let i = 1; i < intervalCount; i++)
      times.push(
        minStartTime
          .clone()
          .add(i * 10, 'm')
          .format(DateFormat.HH_mm)
      )
    return times
  }

  private isAvailableSpot(date: string, spotTime: string): boolean {
    if (
      this.clinic.clinic.holiday_flag &&
      this.publicHolidyList.includes(
        formatDate(new Date(date), DateFormat.YYYY_MM_DD_HYPHEN)
      )
    )
      return false
    const targetMedicalTreatmentSpot = this.medicalTreatmentSpotMap.get(
      formatDate(date, DateFormat.YYYY_MM_DD)
    )
    const hourMinute = spotTime.split(':')
    let spotTimeCondition = spotTime
    switch (hourMinute[1]) {
      case '10':
      case '20':
        spotTimeCondition = hourMinute[0] + ':' + '00'
        break
      case '40':
      case '50':
        spotTimeCondition = hourMinute[0] + ':' + '30'
        break
    }
    const targetSpot = targetMedicalTreatmentSpot!.find(
      spot =>
        formatDate(spot.spot_datetime, DateFormat.HH_mm) === spotTimeCondition
    )
    if (!targetSpot) return false

    switch (hourMinute[1]) {
      case '00':
      case '30':
        return targetSpot.first_unit_available > 0
      case '10':
      case '40':
        return targetSpot.second_unit_available > 0
      case '20':
      case '50':
        return targetSpot.third_unit_available > 0
      default:
        return false
    }
  }

  private selectTreatmentSpot(date: string, spotTime: string) {
    const targetMedicalTreatmentSpot = this.medicalTreatmentSpotMap.get(
      formatDate(date, DateFormat.YYYY_MM_DD)
    )
    const hourMinute = spotTime.split(':')
    let spotTimeCondition = spotTime
    switch (hourMinute[1]) {
      case '10':
      case '20':
        spotTimeCondition = hourMinute[0] + ':' + '00'
        break
      case '40':
      case '50':
        spotTimeCondition = hourMinute[0] + ':' + '30'
        break
    }
    const targetSpot = targetMedicalTreatmentSpot!.find(
      spot =>
        formatDate(spot.spot_datetime, DateFormat.HH_mm) === spotTimeCondition
    )
    let selectSlot = 0
    switch (hourMinute[1]) {
      case '10':
      case '40':
        selectSlot = 1
        break
      case '20':
      case '50':
        selectSlot = 2
        break
    }
    const spotRequest: SpotRequest = {
      id: targetSpot!.id,
      spot_datetime: targetSpot!.spot_datetime,
      select_slot_number: selectSlot
    }
    if (this.reservationType) spotRequest.range_time = targetSpot!.range_time
    this.$emit('on-treatment-spot-select', date, spotRequest)
  }
}
