




















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClinicResponse } from '@/interface/api'
import {
  IsFirstTreatmentRadioOption,
  TreatmentCategory,
  TreatmentCategoryOption
} from '@/utils/constants'

import {
  filterTreatmentCourseCategory,
  filterTreatmentFirstTreatment
} from './utils'

@Component({
  name: 'TreatmentDetailTemplate'
})
export default class TreatmentDetailTemplate extends Vue {
  @Prop({ default: () => null }) private selectedClinic!: ClinicResponse
  @Prop({ default: '' }) private clinicalDepartmentId!: string
  @Prop({ default: 1 }) private firstTreatment!: 0 | 1
  @Prop({ default: '' }) private treatmentCategoryName!:
    | ''
    | 'face_to_face'
    | 'video'

  @Prop({ default: false }) private loading!: boolean
  @Prop({ default: false }) private canFaceToFace!: boolean
  @Prop({ default: false }) private canOnlineTreatment!: boolean
  @Prop({ default: '' }) private treatmentCourseId!: string
  @Prop({ default: '' }) private isSingleClinic!: boolean

  private selectedClinicalDepartmentId = ''
  private isFirstTreatment: 0 | 1 = 1
  private selectedTreatmentCategoryName: '' | 'face_to_face' | 'video' = ''
  private selectedClinicDeaprtmentTreatmentCourseId = ''
  private get isFirstTreatmentRadioOption(): typeof IsFirstTreatmentRadioOption {
    return IsFirstTreatmentRadioOption
  }

  private get treatmentCategoryOption(): typeof TreatmentCategoryOption {
    const option = []
    const department = this.selectedClinic.clinical_departments.find(
      item => item.id === this.selectedClinicalDepartmentId
    )
    if (this.canFaceToFace && department?.has_face_to_face) {
      const target = TreatmentCategoryOption.find(
        option => option.name === TreatmentCategory.FcaeToFace
      )
      option.push(target!)
    }
    if (this.canOnlineTreatment && department?.has_video) {
      const target = TreatmentCategoryOption.find(
        option => option.name === TreatmentCategory.Video
      )
      option.push(target!)
    }
    this.selectedTreatmentCategoryName = option[0].value as
      | 'face_to_face'
      | 'video'
    return option
  }

  private get treatmentCourseOptions() {
    if (this.selectedClinicalDepartmentId === '') {
      return []
    }
    if (!this.selectedTreatmentCategoryName) {
      return []
    }
    const department = this.selectedClinic.clinical_departments.find(
      item => item.id === this.selectedClinicalDepartmentId
    )
    if (!department) {
      return []
    }
    if (!department.treatment_courses) {
      return []
    }
    const target = department.treatment_courses
      .filter(course =>
        filterTreatmentCourseCategory(
          course,
          this.selectedTreatmentCategoryName as 'face_to_face' | 'video'
        )
      )
      .filter(course =>
        filterTreatmentFirstTreatment(course, this.isFirstTreatment)
      )
    if (target.length) {
      if (
        !target.find(
          v => v.id === this.selectedClinicDeaprtmentTreatmentCourseId
        )
      ) {
        this.selectedClinicDeaprtmentTreatmentCourseId = target[0].id || ''
      }
    } else if (!target.length) {
      this.selectedClinicDeaprtmentTreatmentCourseId = ''
    }
    return target
  }

  @Watch('clinicalDepartmentId', { immediate: true })
  private onClinicalDepartmentIdChange() {
    if (!this.clinicalDepartmentId) return
    this.selectedClinicalDepartmentId = this.clinicalDepartmentId
  }

  @Watch('firstTreatment', { immediate: true })
  private onFirstTreatmentChange() {
    this.isFirstTreatment = this.firstTreatment
  }

  @Watch('treatmentCategoryName', { immediate: true })
  private onTreatmentCategoryNameChange() {
    if (!this.treatmentCategoryName) return
    this.selectedTreatmentCategoryName = this.treatmentCategoryName
  }

  @Watch('treatmentCourseId', { immediate: true })
  private onTreatmentCourseIdChange() {
    this.selectedClinicDeaprtmentTreatmentCourseId = this.treatmentCourseId
  }

  @Watch('selectedClinicalDepartmentId', { immediate: true })
  private onChangeDepartmentId() {
    this.selectedClinicDeaprtmentTreatmentCourseId = ''
  }
}
