import { render, staticRenderFns } from "./TreatmentSpotSelectPerTenMinuteTemplate.vue?vue&type=template&id=7f8835f6&scoped=true&"
import script from "./TreatmentSpotSelectPerTenMinuteTemplate.vue?vue&type=script&lang=ts&"
export * from "./TreatmentSpotSelectPerTenMinuteTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./TreatmentSpotSelectPerTenMinuteTemplate.vue?vue&type=style&index=0&id=7f8835f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f8835f6",
  null
  
)

export default component.exports