/* GoogleMapリンクのBaseURL */
export const GOOGLE_MAP_LINK_BASE_URL = 'https://www.google.com/maps/search/'

/* 医師希望なしの場合のmemberId */
export const NO_DESIRED_DOCTOR = {
  label: '希望なし',
  value: 'noDesiredDoctor'
}

/** 日付フォーマット */
export enum DateFormat {
  MM_DD_HH_mm = 'MM/DD HH:mm',
  YYYY_MM_DD_HH_mm = 'YYYY/MM/DD HH:mm',
  YYYY_MM_DD_HH_mm_ss = 'YYYY/MM/DD HH:mm:ss',
  YYYYMMDD = 'YYYYMMDD',
  YYYY_MM_DD = 'YYYY/MM/DD',
  YYYYNEN_MMGATU_DDNICHI = 'YYYY年MM月DD日',
  YYYYNEN_MMGATU_DDNICHI_ZIHUN = 'YYYY年MM月DD日 HH:mm',
  YYYY_MM_DD_HYPHEN = 'YYYY-MM-DD',
  YYYY_MM_HYPHEN = 'YYYY-MM',
  Y = 'Y',
  M = 'M',
  D = 'D',
  dddd = 'dddd',
  HH_mm = 'HH:mm',
  mm = 'mm',
  dd = 'dd'
}

/** 初診・再診選択肢 */
export const IsFirstTreatmentRadioOption = [
  {
    value: 1,
    label: '初診'
  },
  {
    value: 0,
    label: '再診'
  }
]

/** 診察タイプカテゴリ */
export enum TreatmentCategory {
  Video = 'video',
  FcaeToFace = 'face_to_face'
}

/** 診察カテゴリ選択肢 */
export const TreatmentCategoryOption = [
  {
    name: TreatmentCategory.Video,
    label: 'オンライン診療',
    value: TreatmentCategory.Video.toString()
  },
  {
    name: TreatmentCategory.FcaeToFace,
    label: '対面',
    value: TreatmentCategory.FcaeToFace.toString()
  }
]

/** システムカラー */
export const SystemColors = [
  '#FB5779',
  '#FF7A1A',
  '#FFAA0A',
  '#FFD30F',
  '#ADE60F',
  '#16DC7E',
  '#07D4C8',
  '#48DBFD',
  '#0064FB',
  '#5B4FFA',
  '#9F46E4',
  '#FA70FA',
  '#FD5DAD',
  '#FFA5BC',
  '#6B85A0'
]

/** 画像のファイルのMIMEタイプ */
export class AcceptableFile {
  public static MIME_TYPE: Array<'image/png' | 'image/jpeg'> = [
    'image/png',
    'image/jpeg'
  ]
}
