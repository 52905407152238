


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TreatmentSpotSelectPerTenMinuteTemplate from '@/views/treatment-reservation/organisms/TreatmentSpotSelectPerTenMinuteTemplate.vue'
import TreatmentSpotSelectPerThirtyMinuteTemplate from '@/views/treatment-reservation/organisms/TreatmentSpotSelectPerThirtyMinuteTemplate.vue'
import { ClinicResponse, MedicalTreatmentSpot, Spot } from '@/interface/api'
import { DateFormat } from '@/utils/constants'
import { formatDate } from '@/utils/index'
@Component({
  name: 'SelectDateTimeTemplate',
  components: {
    TreatmentSpotSelectPerTenMinuteTemplate,
    TreatmentSpotSelectPerThirtyMinuteTemplate
  }
})
export default class SelectDateTimeTemplate extends Vue {
  @Prop({ default: '' }) private medicalBusinessId!: string
  @Prop({ default: '' }) private clinicalDepartmentId!: string
  @Prop({ default: 1 }) private isFirstTreatment!: number
  @Prop({ default: '' }) private treatmentCategoryName!: string
  @Prop({ default: 0 }) private reservationType!: number
  @Prop({ default: 30 }) private perMinute!: string
  @Prop({ default: () => null }) private clinic!: ClinicResponse
  @Prop({ default: () => [] }) private publicHolidyList!: string[]

  @Prop({ default: () => null }) private medicalTreatmentSpotMap!: Map<
    string,
    Spot[]
  >

  @Prop({ default: () => [] })
  private medicalTreatmentSpots!: MedicalTreatmentSpot[]

  private startDate = new Date()
  private loading = false
  private weeksLater = 0

  private get endDate(): Date {
    return new Date(
      this.startDate.getFullYear(),
      this.startDate.getMonth(),
      this.startDate.getDate() + 6
    )
  }

  private get formatedStartDate(): string {
    return formatDate(this.startDate, DateFormat.YYYYNEN_MMGATU_DDNICHI)
  }

  private get formatedEndDate(): string {
    return formatDate(this.endDate, DateFormat.YYYYNEN_MMGATU_DDNICHI)
  }

  private prev() {
    this.weeksLater--
    this.startDate = new Date(
      this.startDate.setDate(this.startDate.getDate() - 7)
    )
  }

  private next() {
    this.weeksLater++
    this.startDate = new Date(
      this.startDate.setDate(this.startDate.getDate() + 7)
    )
  }

  private today() {
    this.weeksLater = 0
    this.startDate = new Date()
  }

  @Watch('startDate')
  public async onStartDateChange(): Promise<void> {
    this.$emit('on-start-date-change', this.startDate)
  }
}
