





























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { CustomerFreeDescribe, PersonalInfomation } from '@/interface/api'
import { RouteName } from '@/router/index'
import { DateFormat } from '@/utils/constants'
import { formatDate } from '@/utils/index'
import { is050CellPhoneNumber } from '@/utils/validate'

@Component({
  name: 'PersonalInfomationTemplate',
  components: { ValidationProvider, ValidationObserver }
})
export default class PersonalInfomationTemplate extends Vue {
  public $refs!: {
    form: HTMLFormElement
    phoneNumber: HTMLFormElement
  }

  @Prop({ default: false }) private loading!: boolean
  @Prop({ default: 0 }) private webReservationSexChoices!: number
  @Prop({ default: 0 }) private requireKanjiName!: number
  @Prop({ default: () => [] })
  private customerFreeDescribe!: CustomerFreeDescribe[]

  private phoneNumber = ''
  private lastName = ''
  private firstName = ''
  private lastNameKana = ''
  private firstNameKana = ''
  private customerDescription = ''
  private selectedSex =
    this.webReservationSexChoices === 0 || this.webReservationSexChoices === 2
      ? 1
      : 0

  private year = 1970
  private month = 1
  private optionMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  private day = 1
  private checkbox = false
  private birthdayError = ''

  private get getYears(): number[] {
    const goBackYears = 100
    const currentYear = new Date().getFullYear()
    const startYear = currentYear - goBackYears
    return [...Array(goBackYears + 1).keys()].map(x => x + startYear)
  }

  private get getDays(): number[] {
    const maxDate = this.getFinalDate(this.year, this.month)
    return [...Array(maxDate).keys()].map(x => x + 1)
  }

  private get displaySex(): boolean {
    return (
      this.webReservationSexChoices === 0 || this.webReservationSexChoices === 1
    )
  }

  private get routeName() {
    return RouteName
  }

  private getFinalDate(year: number, month: number) {
    return new Date(year, month, 0).getDate()
  }

  private validateBirthdate(): boolean {
    const date = new Date(this.year, this.month - 1, this.day)
    if (
      date.getFullYear() === this.year &&
      date.getMonth() === this.month - 1 &&
      date.getDate() === this.day
    ) {
      this.birthdayError = ''
      return true
    }
    this.birthdayError = '正しい日付を選択してください'
    return false
  }

  private validate050PhoneNumber(): boolean {
    if (is050CellPhoneNumber(this.phoneNumber)) {
      this.$refs.phoneNumber.setErrors([
        '050から始まる電話番号はご利用できません'
      ])
      return false
    }
    return true
  }

  private async send() {
    const validateResult = await this.$refs.form.validate()
    const validate050PhoneNumber = this.validate050PhoneNumber()
    const validateBirthdateResult = this.validateBirthdate()
    if (!validateResult || !validate050PhoneNumber || !validateBirthdateResult)
      return
    const personalInfomation: PersonalInfomation = {
      phone_number: this.phoneNumber,
      last_name: this.lastName,
      first_name: this.firstName,
      last_name_kana: this.lastNameKana,
      first_name_kana: this.firstNameKana,
      sex: this.selectedSex,
      birthdate: formatDate(
        new Date(this.year, this.month - 1, this.day),
        DateFormat.YYYY_MM_DD_HYPHEN
      )
    }
    this.checkbox = false
    this.$emit(
      'on-input-personal-info',
      personalInfomation,
      this.customerDescription
    )
  }
}
