































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Member } from '@/interface/api'
import { NO_DESIRED_DOCTOR } from '@/utils/constants'

@Component({
  name: 'SelectDoctorTemplate'
})
export default class SelectDoctorTemplate extends Vue {
  @Prop({ default: () => [] }) private doctors!: Member[]
  @Prop({ default: false }) private loading!: boolean
  private selectedDoctorId = NO_DESIRED_DOCTOR.value

  private get noDesiredDoctor() {
    return NO_DESIRED_DOCTOR
  }

  private get doctorName() {
    if (this.selectedDoctorId === NO_DESIRED_DOCTOR.value)
      return NO_DESIRED_DOCTOR.label
    const target = this.doctors.find(
      doctor => doctor.id === this.selectedDoctorId
    )
    return target!.last_name + target!.first_name
  }
}
