import request from '@/utils/request'
import { CustomerFreeDescribe, SearchParams } from '@/interface/api'

const prefix = '/api/v0/customer/clinics/treatment-customer-descriptions'

export const retrieveCustomerDescription = (
  medicalBusinessId: string,
  clinicId: string,
  clinicalDepartmentId: string,
  params: SearchParams
): Promise<CustomerFreeDescribe[]> =>
  request({
    url: `${prefix}/${medicalBusinessId}/${clinicId}/${clinicalDepartmentId}/`,
    method: 'get',
    params: params
  }).then(response => response.data.data.attributes)
